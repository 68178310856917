article {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5em;
  }
  p {
    font-size: 1em;
    line-height: 1.8;
    color: #0d0f0f;
  }
  li {
    font-size: 1em;
    margin-bottom: 0.5em;
  }
  a {
    color: hsl(240, 68%, 50%);
  }
  blockquote {
    border-left: rgb(71, 71, 71) 3px solid;
    padding: 1em;
    background-color: #ececec;
    font-style: italic;
    font-size: 1.15em;
    line-height: 2em;
  }
  code {
    font-size: 18px;
    background: rgba(0,0,0,.05);
    border-radius: 2px;
    padding: 3px 5px;
  }
}